/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'list-stars': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" fill-rule="evenodd" d="M5 11.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0-4a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5m0-4a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5"/><path pid="1" d="M2.242 2.194a.27.27 0 01.516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 00-.094.3l.173.569c.078.256-.213.462-.423.3l-.417-.324a.27.27 0 00-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 00-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 00.259-.194zm0 4a.27.27 0 01.516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 00-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.27.27 0 00-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 00-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 00.259-.194zm0 4a.27.27 0 01.516 0l.162.53c.035.115.14.194.258.194h.551c.259 0 .37.333.164.493l-.468.363a.28.28 0 00-.094.3l.173.569c.078.255-.213.462-.423.3l-.417-.324a.27.27 0 00-.328 0l-.417.323c-.21.163-.5-.043-.423-.299l.173-.57a.28.28 0 00-.094-.299l-.468-.363c-.206-.16-.095-.493.164-.493h.55a.27.27 0 00.259-.194z"/>',
    },
});
